import { push } from 'connected-react-router';
import * as actionTypes from './actionTypes';
import { carregarInscricao } from './inscricao.actions';
import { autenticar, logoff } from '../servicos/autenticacao';
import { apiDaInscricao } from '../servicos/axios';
import { cargaInicial } from './cargaInicial.actions';
import { consultarExistenciaDeInscricao } from '../servicos/inscricao';
import { obteveInformacoesDoTitular } from './dadosTitular.actions';
import { ACESSO_ADMINISTRATIVO } from '../helpers/tiposDeAcesso';

export const efetuarLoginSucesso = dadosLogin =>
  ({ type: actionTypes.EFETUAR_LOGIN_SUCESSO, dadosLogin });

export const logOut = () =>
  ({ type: actionTypes.LOG_OUT });

export const efetuarLogin = dadosLogin =>
  async dispatch => {
    const cpf = dadosLogin.cpf.replace(/\.|-/g, '');
    const resultadoDaConsulta = await consultarExistenciaDeInscricao(cpf, dispatch);

    if (!resultadoDaConsulta.possuiInscricao && resultadoDaConsulta.mensagem) {
      throw {
        response: {
          mensagem: resultadoDaConsulta.mensagem
        }
      };
    }

    const inscricao = await autenticar(dadosLogin);

    dispatch(efetuarLoginSucesso({
      ...dadosLogin,
      cpf
    }));

    dispatch(carregarInscricao(inscricao));

    if (inscricao)
      dispatch(push('/menu-inscricao'));
  };

export const efetuarLoginPorCpf = dadosLogin =>
  async dispatch => {
    const { data: inscricao } = await apiDaInscricao().get(`/inscricao/${dadosLogin.cpf}`);

    if (!inscricao)
      dispatch(obteveInformacoesDoTitular({nome: dadosLogin.nome}));

    dispatch(carregarInscricao(inscricao));
    dispatch(efetuarLoginSucesso(dadosLogin));

    if (inscricao)
      dispatch(push('/menu-inscricao'));
  };

export const efetuarLogOut = () => {
  const urlSeguranca = process.env.REACT_APP_URL_SEGURANCA;
  const returnUrl = process.env.REACT_APP_RETURN_URL;
  
  if (ACESSO_ADMINISTRATIVO == localStorage.getItem('tipo_acesso')){

    logoff();
    window.location.href = `${urlSeguranca}/Seguranca/LogOffExterno` + returnUrl;
    return dispatch => {
      dispatch(logOut());
    };
  }else
    return dispatch => {
      logoff();
      dispatch(logOut());
      dispatch(cargaInicial());
      dispatch(push('/'));
    };
};